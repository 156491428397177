import * as H from 'history';
import { SortingRule } from 'react-table';
import { ThunkAction } from 'redux-thunk';
import {
    AppSyncItemStatus,
    jobDefaultTypeEnum,
    jobTypesIdEnumInverse,
    partTypeEnum,
    productCommunicationMethodEnum
} from './models-enums';
import { IinitialState } from './reducers';

export type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;

/* ***** Common between Mobile and Desktop ****** */

export interface IpostalCode extends IbaseDataObject {
    userID: string;
    postalCode: string;
}

export type Ihistory = H.History;

export interface Iuser {
    password: string;
    username: string;
    isAuthenticated: boolean; // FE only
    isLoggingOut: boolean; // FE only
    token: string;
    email: string;
    securityFunctions: string[];
    phone: string;
    first: string;
    last: string;
    position: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    id: string;
    managerID?: string;
    tempAddress?: string;
    tempAddress2?: string;
    tempCity?: string;
    tempState?: string;
    tempZip?: string;
    lastLoginDate?: string;
    createDate?: string;
    countryID: string;
    userCountries: string[];
    postalCodes?: IpostalCode[];
    facilities: IfacilityWithoutBuildings[];
    hasTeamMembers: boolean;
    manager?: any;
    isActive: boolean;
    // related to PartStock
    country?: IresultsCountry;
    engineerVanID?: string;
    sapEngineerID?: string;
    importErrors?: string[];
    isApproved?: boolean;
    salesManagerID?: string;
    tempCompany?: string;
    tempPassword?: string;
    type?: number;
    widgets?: string;
    language?: number;
    nonProductiveHours?: INonProductiveHour[];
    showRecordHours?: boolean;
    userStandards: string[];
    sapPlant?: string;
    brand?: Ibrand;
}

export interface IbaseDataObject {
    id: string;
    createDate?: string;
    updateDate?: string;
    creatorID?: string;
    updaterID?: string;
    isDeleted: boolean;
}
export interface ImainCategory extends IbaseDataObject {
    name: string;
}

export interface Isubcategory extends IbaseDataObject {
    mainCategoryID: string;
    mainCategory: IbaseDataObject;
    // mainCategoryIDs: string[];
    name: string;
}

export interface Istandard extends IbaseDataObject {
    name: string;
}

export interface Ibrand extends IbaseDataObject {
    code: string;
    name: string;
    isManufacturer: boolean;
}

export interface IproductType extends IbaseDataObject {
    code: string;
    mainCategoryIDs: string[];
    name: string;
}

export interface Ipower extends IbaseDataObject {
    name: string;
}

export interface IsystemSize extends IbaseDataObject {
    code: string;
    mainCategoryIDs: string[];
    name: string;
}

export interface IinstallBase extends IbaseDataObject {
    productID: string;
    facilityID: string;
    facility?: Ifacility;
    nickname?: string;
    remarks?: string;
    serialNumber?: string;
    rfid?: string;
    installDate?: string;
    prodDate?: string;
    buildingID?: string;
    floorID?: string;
    locationID?: string;
    system?: number;
    roomID?: string;
    quantity?: number;
    position?: string;
    importedLocation?: string;
    workOrders?: IWorkOrder[]; // is included from /inventory/search when includeWorkOrders=true
    measurementPointListResultStatus: number;
    latestMeasurementPointListResultStatus?: number; // FE only
    workOrderResultStatus: number;
    latestMeasurementPointListResultID: string; // latestAnnualMeasurementPointListResultID
    latestAGSMeasurementPointListResultID: string;
    latestCommissioningMeasurementPointListResultID: string;
    latestVerificationMeasurementPointListResultID: string;
    latestAuditMeasurementPointListResultID: string;
    lastQuarterlyMaintenanceDate: string;
    lastSixMonthMaintenanceDate: string;
    lastYearlyMaintenanceDate: string;
    lastTwoYearMaintenanceDate: string;
    lastThreeYearMaintenanceDate: string;
    lastFiveYearMaintenanceDate: string;
    objectPartGroup: string;
    sapEquipmentNumber?: string;
    workOrderID?: string;
    jobWorkorderID?: string;
    // locationString: string
}
export interface IcommissioningInstallBase extends IbaseDataObject {
    jobID: string;
    installBaseID: string;
}

export interface IcommissioningDataForm {
    agssMinPressureFlow: number;
    agssPressureFlow: number;
    agssSystemFlow: number;
    agssTestFlow: number;
    certifiedGaugeNumber: string;
    co2MinPressureFlow: number;
    co2PressureFlow: number;
    co2SystemFlow: number;
    co2TestFlow: number;
    ma4MinPressureFlow: number;
    ma4PressureFlow: number;
    ma4SystemFlow: number;
    ma4TestFlow: number;
    n20MinPressureFlow: number;
    n20PressureFlow: number;
    n20SystemFlow: number;
    n20TestFlow: number;
    o2MinPressureFlow: number;
    o2PressureFlow: number;
    o2SystemFlow: number;
    o2TestFlow: number;
    saMinPressureFlow: number;
    saPressureFlow: number;
    saSystemFlow: number;
    saTestFlow: number;
    vacMinPressureFlow: number;
    vacPressureFlow: number;
    vacSystemFlow: number;
    vacTestFlow: number;
    [key: string]: number | string;
}

export interface IcommissioningDataPayload {
    jobID: string;
    form: any;
}

export interface IcommissioningData extends IbaseDataObject {
    jobID: string;
    agssMinPressureFlow: number;
    agssPressureFlow: number;
    agssSystemFlow: number;
    agssTestFlow: number;
    certifiedGaugeNumber: string;
    cO2MinPressureFlow: number;
    cO2PressureFlow: number;
    cO2SystemFlow: number;
    cO2TestFlow: number;
    mA4MinPressureFlow: number;
    mA4PressureFlow: number;
    mA4SystemFlow: number;
    mA4TestFlow: number;
    n20MinPressureFlow: number;
    n20PressureFlow: number;
    n20SystemFlow: number;
    n20TestFlow: number;
    o2MinPressureFlow: number;
    o2PressureFlow: number;
    o2SystemFlow: number;
    o2TestFlow: number;
    saMinPressureFlow: number;
    saPressureFlow: number;
    saSystemFlow: number;
    saTestFlow: number;
    vacMinPressureFlow: number;
    vacPressureFlow: number;
    vacSystemFlow: number;
    vacTestFlow: number;
}

export interface IproductLink extends IbaseDataObject {
    productID: string;
    url: string;
    type: number;
    other: string;
}

export interface IproductStandard extends IbaseDataObject {
    productID: string;
    standardID: string;
}
export interface Iproduct {
    id: string;
    name: string;
    sku: string;
    description: string;
    imagePath: string;
    subcategoryID: string;
    origin: string;
    brandID: string;
    productTypeID: string;
    powerID: string;
    systemSizeID: string;
    subcategory?: Isubcategory;
    isDeleted: boolean;
    isApproved: boolean;
    mergedProductID: string;
    isCompliant: boolean;
    hasSpares: boolean;
    isActive: boolean;
    communicationMethod: productCommunicationMethodEnum;
    links: IproductLink[];
    sapMaterialNumber?: string;
    isFinalProduct: boolean;
    productStandards: IproductStandard[];
    createDate: Date | undefined;
    updateDate: Date | undefined;
    pgc: string; // Product/Object Group Code
}

/**
 * Shopping Cart Models
 */
export interface IquotePartCart extends IbaseDataObject {
    message: string;
    quoteItems: IquotePartPopulated[];
    jobID?: string;
    quantity?: number;
    facilityID?: string;
    isDeleted: boolean;
}

export interface IquotePart extends IbaseDataObject {
    partID: string;
    installBaseID?: string;
    quantity?: number;
    bundle: number;
}

export interface IquotePartPopulated extends IquotePart {
    part: Ipart;
}

export interface IshoppingCartItem {
    name: string;
    quantity?: number;
    installBaseID?: string;
    id: string;
    cost?: number;
    bundle?: number;
}

export interface IshoppingCartGroupedItem {
    name: string;
    subItems: Array<{ name: string; subSubItems: IshoppingCartItem[] }>;
}

export interface IshoppingCart {
    addedIDs: string[];
    itemsByID: { [key: string]: IshoppingCartItem };
    message: string;
}

export interface IshoppingCartWithGroupedItems extends IshoppingCart {
    groupedItems: IshoppingCartGroupedItem[]; // populated in a selector
    laborItems: IshoppingCartItem[];
}

/*
 * Location Models
 */
export interface Ibuilding extends IbaseDataObject {
    name: string;
    floors: Ifloor[];
    facilityID: string;
}

export interface Ifloor extends IbaseDataObject {
    name: string;
    locations: Ilocation[];
    buildingID: string;
}

export interface Ilocation extends IbaseDataObject {
    name: string;
    rooms: Iroom[];
    floorID: string;
}

export interface Iroom extends IbaseDataObject {
    name: string;
    locationID: string;
}

export interface IproductInfo {
    brandOptions: Ioption[];
    brands: { [key: string]: Ibrand };
    mainCategories: { [key: string]: ImainCategory };
    mainCategoryOptions: Ioption[];
    powerOptions: Ioption[];
    powers: { [key: string]: Ipower };
    productTypeOptions: Ioption[];
    productTypes: { [key: string]: IproductType };
    standardOptions: Ioption[];
    standards: { [key: string]: Istandard };
    subcategories: { [key: string]: Isubcategory };
    subcategoryOptions: Ioption[];
    systemSizeOptions: Ioption[];
    systemSizes: { [key: string]: IsystemSize };
    originOptions: Ioption[];
}

export interface IuserJob extends IbaseDataObject {
    userID: string;
    jobID: string;
    user: Iuser;
}

export interface IjobWorkOrder extends IbaseDataObject {
    jobID: string;
    workOrderID: string;
    workOrder?: IWorkOrder;
}

//  PARTS
export interface Ipart extends IbaseDataObject {
    description: string;
    number: string;
    installationTime: number;
    isActive: boolean;
    measurement: string;
    quantity?: number;
    origin: string;
    type: partTypeEnum;

    bundle?: number;
    partID?: string;
}

export interface IpartStock extends IbaseDataObject {
    partID: string;
    userID: string;
    quantity: number;
    tLot: string;
}

export interface IjobPart extends IbaseDataObject {
    jobID: string;
    partID: string;
    estimated: number;
    used: number;
    lot: string;
}

export interface IworkOrderPart extends IbaseDataObject {
    workOrderID: string;
    partID: string;
    estimated: number;
    used: number;
}

export interface IinstallBasePart extends IbaseDataObject {
    installBaseID: string;
    partStockID?: string | null;
    jobPartID?: string | null;
    directPartID?: string | null;
    directPartQuantity?: number;
    jobPartQuantity?: number;
    partStockQuantity?: number;
    workOrderPartID?: string | null;
    workOrderPartQuantity?: number;
    jobID: string;
    fromVirtualInstallBase?: boolean;
}

export interface IinstallBasePartPopulated extends IinstallBasePart {
    part: Ipart;
}

export interface IsuggestedPart extends IbaseDataObject {
    productID: string;
    partID: string;
    productQuantity: number;
    adminComments: string;
    installBaseID: string;
    InstallBaseQuantity: number;
}

export interface IsuggestedPartPopulated extends IsuggestedPart {
    part: Ipart;
}

// JOBS
export interface Ijob extends IbaseDataObject {
    coverLetter?: string;
    hospitalSignatureUrl?: string;
    isRiskAccepted?: boolean;
    facilityID: string;
    assignedUserID: string;
    jobTypeID: keyof typeof jobTypesIdEnumInverse;
    startDate: string;
    endDate: string;
    status: string;
    jobNumber: string;
    jobHours: IjobHour[];
    fseNotes: string;
    source: number; // JobSource enum
    isSAPClosed: boolean;
}

export interface IjobPopulated extends Ijob {
    facility: Ifacility;
    jobParts?: IjobPart[];
    userJobs?: IuserJob[];
    jobWorkOrders?: IjobWorkOrder[];
}

export interface Itile {
    icon: string | string[];
    title: string;
    src: string;
    srcBanner: string;
    color: 'blue' | 'green' | 'dark' | 'orange' | 'purple' | 'grey';
    width: number;
    height: number;
    url: string;
    securityFunction: string;
    description: string;
}
export interface Ifacility {
    id: string;
    name: string;
    name2: string;
    countryID: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    buildings: Ibuilding[];
    isDeleted: boolean;
    standardID: string;
    preventativeMaintenanceEnabled?: boolean;
}

export interface IfacilityWithoutBuildings extends IbaseDataObject {
    name: string;
    name2?: string;
    address: string;
    address2: string;
    city: string;
    countryID: string;
    state: string;
    postalCode: string;
    standardID: string;
    contactName1: string;
    contactName2: string;
    contactName3: string;
    contactEmail1: string;
    contactEmail2: string;
    contactEmail3: string;
    contactPhone1: string;
    contactPhone2: string;
    contactPhone3: string;
    sapFacilityNumber: string;
    preventativeMaintenanceEnabled: boolean;
    // related to PartStock
    agslevProviderID?: string;
    authorizedCompanyID?: string;
    authorizedPerson?: string;
    buildings?: Ibuilding[];
    comments?: string;
    contactFacilities?: IcontactFacility[];
    contract?: IfacilityContract;
    environmentalTestProviderID?: string;
    inspectionProviderID?: string;
    justSynced?: boolean;
    leadFSEID?: string;
    maintenanceProviderID?: string;
    managedByID?: string;
    mgpsSite?: boolean;
    siteSize?: number;
    syncedBy?: number;
    type?: string;
    website?: string;
}

export interface IbareFacility extends IbaseDataObject {
    name: string;
    address: string;
    address2: string;
    city: string;
    countryID: string;
    state: string;
    postalCode: string;
    standardID: string;
}

export interface Ioption {
    value: any;
    label: string;
}

export interface IoptionNumberValue {
    value: number; // technically this should be a string, but number works just fine
    label: string;
}

/*
 * Measurement Points
 */
export interface ImeasurementPointSelectOption {
    id: string;
    value: string;
    label: string;
    isDeleted?: boolean;
    isDefault?: boolean;
}

export interface ImeasurementPoint {
    id: string;
    type: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    label: string;
    order: number;
    isRequired: boolean;
    showInReport: boolean;
    measurementPointTabID: string;
    guideText?: string;
    helpText?: string;
    allowNotes?: boolean;
    passFailDefault?: number;
    numericMinValue?: number;
    numericMaxValue?: number;
    numericAllowDecimals?: boolean;
    selectDefaultOptionID?: string;
    selectRememberBetweenDevice?: boolean;
    selectRememberBetweenInspection?: boolean;
    selectOptions?: ImeasurementPointSelectOption[];
    isDeleted: boolean;
    jobDefaultType?: jobDefaultTypeEnum;
    defaultText: string;
}

export interface IcheckedItems {
    primaryText: string;
    secondaryText: string;
    passed: CheckedItemStatus;
    optional: boolean;
    applicable: boolean;
    contacts?: IfacilityContact[];
}

export enum CheckedItemStatus {
    pass,
    fail,
    neutral
}

export interface Icountry {
    value: string;
    RegionID: string;
    label: string;
    Currency: string;
}

// this is the model that the backend uses for countries
export interface IcountryBE {
    sapCountryCode?: string;
    regionID: string;
    currency: string;
    region?: string;
    name: string;
    id: string;
    isDeleted: boolean;
    createDate: string;
    updateDate: string;
}

export interface ImainFailureCodes {
    PGC: string;
    MainCodeDescription: string;
    MainCode: string;
}

export interface IsubFailureCodes {
    MainFailureCode: string;
    SubFailureCode: string;
    SubCodeDescription: string;
}

export interface IreasonFailureCodes {
    SubFailureCode: string;
    ReasonFailureCode: string;
    ReasonFailureCodeDescription: string;
}

export interface ImeasurementPointListTab {
    id: string;
    name: string;
    order: number;
    measurementPoints?: { [key: string]: ImeasurementPoint };
    isDeleted: boolean;
}

export interface ImeasurementPointList {
    id: string;
    measurementPointTabs: ImeasurementPointListTab[];
    mainCategoryID: string;
    testProcedures: string;
    standardID: string;
    type: number;
    isDeleted: boolean;
    temporary?: boolean;
    forJob?: boolean;
}

export interface ImeasurementPointAnswer {
    measurementPointID: string;
    pass?: number;
    numericValue?: number;
    textValue?: string;
    dateValue?: string;
    notes?: string;
    measurementPointSelectOptionID?: string;
    showInReport: boolean;
}

export interface ImeasurementPointDefaultAnswer {
    pass?: number;
    numericValue?: number;
    textValue?: string;
    dateValue?: string;
    notes?: string;
    measurementPointSelectOptionID?: string;
    showInReport: boolean;
}

export interface ImeasurementPointResult extends IbaseDataObject {
    jobID: string;
    jobTypeID?: string;
    status: number;
    previousStatus?: number; // FE only
    notes: string;
    temporary?: boolean; // FE only
    measurementPointAnswers: ImeasurementPointAnswer[];
    installBaseID: string;
    measurementPointListID: string;
    manualOverride: boolean;
    DamageCode: IdamageCode | null;
}

export interface ImeasurementPointResultWithType extends ImeasurementPointResult {
    measurementPointListType: number;
}

export interface IsimpleMeasurementPointResult extends IbaseDataObject {
    jobID: string;
    status: number;
    previousStatus?: number; // FE only
    temporary?: boolean; // FE only
    measurementPointAnswers: ImeasurementPointAnswer[];
    compiledNotes?: string;
    measurementPointListID: string;
}

export interface IMPLRwithMeasurementType extends ImeasurementPointResult {
    measurementPointListType: number;
}

// these are copied from react-redux-toastr beacuse could not figure out any other way to make typescript happy
export type transitionInType = 'bounceIn' | 'bounceInDown' | 'fadeIn';
export type transitionOutType = 'bounceOut' | 'bounceOutUp' | 'fadeOut';

export type ThunkResult<R> = ThunkAction<R, IinitialState, undefined, any>;

/*
 *********** Mobile Only Models ***********
 */
export interface IinstallBasePopulated extends IinstallBase {
    product: Iproduct;
    productNameString: string; // FE only
    productName?: string; // FE only
    locationString: string; // FE only
    workOrderID: string | undefined; // FE only
    woNumberString: string | undefined; // SAPWorkOrderContainer only
    activityDescriptionString: string | undefined; // SAPWorkOrderContainer only
    latestMeasurementPointListResult: ImeasurementPointResult;
    // workOrder: IWorkOrder;
    originalMeasurementPointListResultStatus: number;
    isVirtual: boolean;
}

export interface ItableFiltersReducer {
    search: string;
    page: number;
    company?: Ioption;
    commentType?: Ioption;
    startDate?: string;
    endDate?: string;
    createDate?: string;
    dateRange?: Ioption;
    facility?: Ioption;
    mainCategory?: Ioption;
    sorted?: SortingRule[];
    building?: Ioption | null;
    floor?: Ioption | null;
    location?: Ioption | null;
    room?: Ioption | null;
    status?: Ioption;
    system?: IoptionNumberValue;
    showCompleted?: Ioption;
    userID?: Ioption;
    facilityID?: string;
    buildingID?: string;
    floorID?: string;
    locationID?: string;
    rows?: number;
    jobType?: Ioption;
    showAdditionalFilters?: boolean;
    measurementPointListResultStatus?: Ioption;
    tabKey?: any;
    vendor?: number;
    workOrderResultStatus?: Ioption;
    workOrderType?: number;
    originalMeasurementPointListResultStatus?: Ioption;
    hideVirtualToggle?: boolean;
}

export interface IfacilityContact extends IbaseDataObject {
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    phone: string;
    mobile: string;
    enableNotifications: boolean;
    contactFacilities: IcontactFacility[];
    userID?: string;
    user?: Iuser;
    smartlinkNotifications: boolean;
    source: number;
}

export interface ItableFiltersParams {
    search?: string;
    page?: number;
    company?: Ioption;
    commentType?: Ioption;
    startDate?: string;
    endDate?: string;
    createDate?: string;
    facility?: Ioption;
    mainCategory?: Ioption;
    sorted?: SortingRule[];
    building?: Ioption | null;
    floor?: Ioption | null;
    location?: Ioption | null;
    room?: Ioption | null;
    status?: Ioption;
    system?: IoptionNumberValue;
    showCompleted?: Ioption;
    userID?: Ioption;
    facilityID?: string;
    buildingID?: string;
    floorID?: string;
    locationID?: string;
    rows?: number;
    jobType?: Ioption;
    showAdditionalFilters?: boolean;
    measurementPointListResultStatus?: Ioption;
    workOrderResultStatus?: Ioption;
    jobTypeID?: string | string[];
    hideVirtualToggle?: boolean;
}

export interface IjobComment {
    id: string;
    jobID: string;
    userID: string;
    isDeficiency: boolean;
    isInternal: boolean;
    isDeleted: boolean;
    text: string;
    createDate: string;
    codeID: string;
    isResolved: boolean;
}

export interface IofflineFacilityStatus {
    locations: boolean;
    installBases: boolean;
    workOrders: boolean;
    photos: boolean;
    measurementPointResults: boolean;
}

export interface IofflineJobStatus {
    simpleMeasurementPointResults: boolean;
}

export interface IdeficiencyCode extends IbaseDataObject {
    description: string;
    name: string;
    standardID: string;
}

export interface ICode extends IbaseDataObject {
    description: string;
    name: string;
}

export interface IsignatureFormData extends FormData {
    CustomerSignature?: Blob;
    FSESignature?: Blob;
    PrintedAuthorizedCustomerName?: string;
    jobID?: string;
}

export interface AppSyncItem {
    name: string;
    displayName: string;
    status: AppSyncItemStatus;
}

export interface IsyncStatusReducer {
    allProductsUpdated: number;
    productInfoUpdated: number;
    measurementPointsUpdated: number;
    lastSync: number;
    FSEUsersUpdated: number;
    measurementPointResultsUpdated: number;
    simpleMeasurementPointResultsUpdated: number;
    isInitialSyncComplete: boolean;
    allPartsUpdated: number;

    // V2
    initialAppSyncList: AppSyncItem[];
    downloadSpeed: number | undefined;
}

export interface IpreventativeMaintenanceChecklist extends IbaseDataObject {
    preventativeMaintenancePlanID: string;
    scheduleInterval: number;
    name: string;
    preventativeMaintenancePoints: IpreventativeMaintenancePoint[];
}

export interface IpreventativeMaintenancePoint extends IbaseDataObject {
    preventativeMaintenanceChecklistID: string;
    answerType: number;
    order: number;
    label: string;
    trueFalseDefault: 1;
    isRequired: boolean;
    helpText: string;
}

export interface IjobHour extends IbaseDataObject {
    jobID: string;
    number: string;
    description: string;
    quantity: number;
    date: string;
    engineerVanID: string;
    SAPEngineerID: string;
    laborRateID: string;
}

export interface INonProductiveHour extends IbaseDataObject {
    userID: string;
    number: string;
    description: string;
    quantity: number;
    date: string;
    sentToSAP: boolean;
    laborRateID: string;
}

export interface IlaborRate {
    countryType: string;
    code: string;
    description: string;
    type: string;
    id: string;
}

export enum WorkOrderSource {
    MyMedGas = 0,
    SAP = 1
}

export interface IWorkOrder extends IbaseDataObject {
    asset?: string;
    number?: string;
    vendor: number;
    technician?: string;
    contactInfo?: string;
    dueDate?: string;
    closingDate?: string;
    priority: number;
    status: number;
    type: number;
    jobTypeID: string;
    parts?: IworkOrderPart[];
    productName?: string;
    product?: Iproduct;
    productId?: string;
    installBaseLocation?: string;
    activityDescription?: string;
    closingNotes?: string;
    closingNotesList: WOClosingNote[] | null;
    activeClosingNotes: WOClosingNote | null;
    facility?: Ifacility;
    notes?: string;
    installBaseID: string;
    suggestedJobTypeID?: keyof typeof jobTypesIdEnumInverse;
    file1?: any;
    file2?: any;
    file3?: any;
    preventativeMaintenanceChecklist?: IpreventativeMaintenanceChecklist;
    lastServicedDate?: string; // set by the backedn
    attachmentUrl1?: string;
    attachmentUrl2?: string;
    attachmentUrl3?: string;
    jobWorkOrders?: string[];
    source: WorkOrderSource;
    sapBusinessIndicator?: SAPBusinessIndicator;
    sapPlant?: string;
}

export type SAPBusinessIndicator =
    | 'SP'
    | 'CU'
    | 'CC'
    | 'WL'
    | 'WG'
    | 'GL'
    | 'XP'
    | 'FP'
    | 'FO'
    | 'FA'
    | 'FM'
    | 'IP'
    | 'IC'
    | 'IS'
    | 'MF'
    | 'MG'
    | 'MC'
    | 'MP'
    | 'PM'
    | 'PG'
    | 'EC'
    | 'RF'
    | 'RU'
    | 'RA'
    | 'RA'
    | 'GW'
    | 'SG'
    | 'CH'
    | 'FL'
    | 'CM'
    | 'TW'
    | 'VS'
    | '';
export interface WOClosingNote {
    workOrderID: string;
    text: string;
    createDate: string;
}

export interface IjobDefault {
    id: string;
    jobID: string;
    mpType: number;
    mpDefaultType: number;
    productTypeID: string;
    defaultMpAnswer: ImeasurementPointDefaultAnswer;
}

export interface IresultsCountry extends IbaseDataObject {
    sapCountryCode: string;
    region: Iregion;
    regionID: string;
    currency: string;
}

export interface Iregion extends IbaseDataObject {
    continentID: string;
    continent: Icontinent;
    name: string;
}

export interface Icontinent extends IbaseDataObject {
    quotePrefix: string;
    name: string;
}

export interface IfacilityContract extends IbaseDataObject {
    facilityID: string;
    myMedGasContract: number;
    myMedGasContractStart: string;
    myMedGasContractEnd: string;
    myMedGasContractComments: string;
    inspectionContract: number;
    inspectionContractStart: string;
    inspectionContractEnd: string;
    inspectionContractComments: string;
    maintenanceContract: number;
    maintenanceContractStart: string;
    maintenanceContractEnd: string;
    maintenanceContractComments: string;
    agslevContract: number;
    agslevContractStart: string;
    agslevContractEnd: string;
    agslevContractComments: string;
    environmentalContract: number;
    environmentalContractStart: string;
    environmentalContractEnd: string;
    environmentalContractComments: string;
    boomContract: number;
    boomContractStart: string;
    boomContractEnd: string;
    boomContractComments: string;
    leakTestingContract: number;
    leakTestingContractStart: string;
    leakTestingContractEnd: string;
    leakTestingContractComments: string;
    traceGasAnalysisContract: number;
    traceGasAnalysisContractStart: string;
    traceGasAnalysisContractEnd: string;
    traceGasAnalysisContractComments: string;
    otherContract: number;
    otherContractStart: string;
    otherContractEnd: string;
    otherContractComments: string;
}

export interface IcontactFacility {
    contactID?: string;
    contact?: Icontact;
    facilityID: string;
}

export interface Icontact extends IbaseDataObject {
    firstName: string;
    lastName: string;
    title: string;
    email: string;
    phone: string;
    mobile: string;
    enableNotifications: boolean;
    userID: string;
    contactFacilities: null[];
    source: number;
}
export interface Iphoto extends IbaseDataObject {
    name: string;
    comments: string;
    file: string;
    fileUri?: string;
    fileType: number;
    id: string;
    installBaseID: string;
    isDeleted: boolean;
    isInReport: boolean;
    jobID: string;
    lightboxUrl: string;
    thumbnailUrl: string;
    createDate: string;
    updateDate: string;
    installBase: IinstallBase;
    product: Iproduct;
    productID: string;
}

export interface ImanageAssetPhotosReducer {
    photos: { [key: string]: Iphoto };
}

export interface JobNewInstallBases {
    jobInstallBases: { jobID: string; installbaseID: string }[];
}

export interface IdefaultReport {
    id: string;
    reportType: number;
    defaultCoverLetter: string;
    name: string;
}

export interface IjobSignature {
    id: string;
    jobID: string;
    type: number;
    uri?: string;
    signedBy?: string;
    signedByPosition?: string;
}

export interface IsignatureResponse {
    id: string;
    jobID: string;
    type: string;
    signatureType: number;
    signedBy: string;
    signedDate: string;
    signedByPosition: string;
    email: string;
    imageBytes: string;
}

export interface Iconfig {
    isMobile: boolean;
    workOrderLoadedKey: string;
}

export interface IdamageCode {
    objectPartGroup: string;
    objectPartCode: string;
    damageCodeText: string;
    causeCode: string;
    failedPartNumber: string;
    rootCause: string;
    jobID: string;
    installBaseID: string;
}

export interface ImanageCodeReducer {
    codesByID: { [key: string]: ICode };
    // totalPages: number;
    // showEditCodeModal: boolean;
    // tableFilters: ItableFiltersReducer;
    // selectedCodeID: string;
    // codeFormValues: { [key: string]: any };
}

export interface IsapJobMapping {
    sapBusinessIndicator: string;
    isVirtual: boolean;
    jobTypeID: string;
    serviceCenter: string;
    sapMaterialNumber: string;
    isDefault: boolean;
}
